import 'bootstrap-3-typeahead'

const set_country_division_list = function(country_division_id) {
  let country_id = $('#generic_request_country_id').val()
  $.get(`/address_verification/country_division_select/${country_id}?t=${new Date().getTime()}`, function(data) {
    let select = $('#generic_request_country_division_id')
    select.html(data).val(country_division_id).trigger('chosen:updated')
    select.parents('.row').toggle(select.children().length > 0)
  })
}

const switch_name_section = function(ns) {
  $('.name-section-tab').removeClass('active')
  $(`#${ns}-tab`).addClass('active')
  $('.name-section-div').hide()
  $(`#${ns}-div`).show()
  $('#generic_request_contact_type').val(ns)
}

$(function() {
  $('.chzn-select').chosen()

  if ($('#product_options').length > 0) {
    $.get('/generic_requests/products', function() {
      $('#product_options').trigger('chosen:updated')
    })
  }

  let country = $('#generic_request_country_id')
  if (country.length > 0 && country.val() != '') {
    set_country_division_list($('#generic_request_country_division_id').attr('data-id'))
  }

  $('#generic_request_attention_care_of').typeahead({
    highlight: true,
    minLength: 0,
    source: ['ATTN: ', 'ATTN: Waiting Room', 'C/O: ']
  })
})

$(document).on('change chosen:updated', '#generic_request_country_id', function() {
  set_country_division_list()
})

$(document).on('change', '.address_field', function() {
  $('#generic_request_is_verified').val(false)
})

$(document).on('change', '#generic_request_promo_code_id', function() {
  let request_origin_id = $(this).find(':selected').attr('data-request-origin-id')
  $('#generic_request_request_origin_id').val(request_origin_id).trigger('chosen:updated')
})

$(document).on('change', '#generic_request_request_origin_id', function() {
  $('#generic_request_promo_code_id').val('').trigger('chosen:updated')
})

$(document).on('change', '#product_options', function() {
  let id = $(this).find(':selected').val()
  if (id == '') return
  if ($('#selected_products').find(`#generic_request_product_ids_${id}`).length > 0) {
    alert('Product already in the list.')
    return false
  }

  $('#selected_products').append(`
    <div class="removeable row row-skinny row-bottom-space">
      <div class="col-md-12">
        <a href="#" class="remove btn btn-xs btn-danger"><i class="fa fa-xmark"></i></a> ${$(this).find(':selected').text()}
        <input id="generic_request_product_ids_${id}" name="generic_request[product_ids][]" type="hidden" value="${id}">
      </div>
    </div>
  `)
  $(this).val('').trigger('chosen:updated')
})

$(document).on('click', '.remove', function() {
  $(this).closest('.removeable').remove()
})

$(document).on('click', '#individual-tab', function() {
  switch_name_section('individual')
  return false
})

$(document).on('click', '#organization-tab', function() {
  switch_name_section('organization')
  return false
})

$(document).on('keyup', '#individual_first_name, #organization_first_name', function() {
  $('#generic_request_first_name').val($(this).val())
})

$(document).on('addressVerified', '#address_verification_modal', function(e) {
  $('#generic_request_country_id').trigger('chosen:updated')
  set_country_division_list(e.detail.country_division_id)
  $(this).modal('hide')
})
