import ujs from '@rails/ujs'

$(document).on('shown.bs.modal', '#address_verification_modal', function() {
  $('#user_input').trigger('focus').trigger('select')
})

$(document).on('hidden.bs.modal', '#address_verification_modal', function() {
  let country = $('#country_select').attr('data-default')
  $('#user_input').val('')
  $(`#country_select.dropdown-menu li a[data-country="${country}"]`).trigger('click')
  $('#address_verification_results').html('')
})

$(document).on('click', '#country_select.dropdown-menu li a', function() {
  let country_code = $(this).attr('data-country')
  let modal = $('#address_verification_modal')
  modal.find('button .flag').hide()
  modal.find(`button .flag_${country_code}`).show()
  modal.find('input[name=country]').val(country_code)
})

$(document).on('keypress', 'input.address_search_field', function(e) {
  if (e.key == 'Enter') {
    $('#address_verification_submit_button').trigger('click')
    e.preventDefault()
  }
})

$(document).on('click', '#address_verification_submit_button', function() {
  $('#address_verification_results').html('Searching ...')
})

$(document).on('click', '.picklist_item', function() {
  let form = $('form#address_refiner')
  form.find('input[name=user_input]').val('')
  form.find('input[name=moniker]').val($(this).attr('data-moniker'))
  form.find('input[name=display]').val($(this).attr('data-display'))
  form.find('input[name=postcode]').val($(this).attr('data-postcode'))
  form.find('input[name=score]').val($(this).attr('data-score'))
  ujs.fire(form[0], 'submit')
})

$(document).on('keypress', '#address_verification_results', function(e) {
  if (e.key == 'Enter') {
    e.preventDefault()

    if ($('#user_input').is(':focus')) {
      $('#submitButton').trigger('click')
    }
  }
})
