import ujs from '@rails/ujs'
ujs.start()

import './globals/jquery'

import 'bootstrap-sass'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'

import 'chosen-js'
import 'chosen-js/chosen.css'

import '#/layouts/js'
import '#/address_verifications/js'
import '#/generic_requests/js'
